import { NotificationsRoot, TooltipProvider } from '@onsaui'
import { QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'

import AuthGuard from '@/modules/auth/components/AuthGuard/AuthGuard'
import AuthModal from '@/modules/auth/components/AuthModal/AuthModal'
import SignInToContinueModal from '@/modules/auth/components/SignInToContinueModal/SignInToContinueModal'
import BillingPlanSelectModal from '@/modules/billing/components/BillingPlanSelectModal/BillingPlanSelectModal'
import ContactSalesModal from '@/modules/billing/components/ContactSalesModal/ContactSalesModal'
import PaymentFailedModal from '@/modules/billing/components/PaymentFailedModal/PaymentFailedModal'
import PaymentSuccessModal from '@/modules/billing/components/PaymentSuccessModal/PaymentSuccessModal'
import UpgradeToProModal from '@/modules/billing/components/UpgradeToProModal/UpgradeToProModal'
import UpgradeToProPromoModal from '@/modules/billing/components/UpgradeToProPromoModal/UpgradeToProPromoModal'
import PlaygroundLayout from '@/modules/general/components/PlaygroundLayout/PlaygroundLayout'
import WorkspaceLayout from '@/modules/general/components/WorkspaceLayout/WorkspaceLayout'
import IcpLeadDeletingModal from '@/modules/icp/components/IcpLeadDeletingModal/IcpLeadDeletingModal'
import { icpHelpers, IcpStage } from '@/modules/icp/model'
import { ConfirmModal } from '@/modules/shared'
import RegenerateTaskModal from '@/modules/task/components/RegenerateTaskModal/RegenerateTaskModal'
import TaskSharingModal from '@/modules/task/components/TaskSharingModal/TaskSharingModal'
import ConnectLinkedInModal from '@/modules/user/components/ConnectLinkedInModal/ConnectLinkedInModal'
import HubSpotAutoconnect from '@/modules/user/components/HubSpotAutoconnect/HubSpotAutoconnect'
import HubSpotConnectionStatusModal from '@/modules/user/components/HubSpotConnectionStatusModal/HubSpotConnectionStatusModal'
import LinkedInConnectedModal from '@/modules/user/components/LinkedInConnectedModal/LinkedInConnectedModal'
import RedirectInsideWorkspace from '@/modules/workspace/components/RedirectInsideWorkspace/RedirectInsideWorkspace'
import SystemPromptSettingsModal from '@/modules/workspace/components/SystemPromptSettingsModal/SystemPromptSettingsModal'
import AuthRedirectPage from '@/pages/auth-redirect/AuthRedirectPage'
import HomePage from '@/pages/home/HomePage'
import HubSpotHowTo from '@/pages/hubspot-howto/HubSpotHowTo'
import HubSpotRedirectPage from '@/pages/hubspot-redirect/HubSpotRedirectPage'
import IcpCampaignPage from '@/pages/icp-campaign/IcpCampaignPage'
import IcpConfirmationPage from '@/pages/icp-confirmation/IcpConfirmationPage'
import IcpContextPage from '@/pages/icp-context/IcpContextPage'
import IcpEditRedirect from '@/pages/icp-edit-redirect/IcpEditRedirect'
import IcpLeadsPage from '@/pages/icp-leads/IcpLeadsPage'
import IcpOutreachDetailsPage from '@/pages/icp-outreach-details/IcpOutreachDetailsPage'
import IcpRootPage from '@/pages/icp-root-page/IcpRootPage'
import IcpTemplatesPage from '@/pages/icp-templates/IcpTemplatesPage'
import IcpViewPage from '@/pages/icp-view/IcpViewPage'
import IcpWrapperPage from '@/pages/icp-wrapper/IcpWrapperPage'
import PlaygroundPage from '@/pages/playground/PlaygroundPage'
import PlaygroundTaskPage from '@/pages/playground-task/PlaygroundTaskPage'
import PublicTaskPage from '@/pages/public-task/PublicTaskPage'
import SalesBotPage from '@/pages/sales-bot/SalesBotPage'
import SignInPage from '@/pages/sign-in/SignInPage'
import SignOutPage from '@/pages/sign-out/SignOutPage'
import TestUIPage from '@/pages/test-ui/TestUIPage'
import WorkspacePage from '@/pages/workspace/WorkspacePage'
import WorkspaceTaskPage from '@/pages/workspace-task/WorkspaceTaskPage'
import { QueryService } from '@/services/query'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <NotificationsRoot />

        <AuthModal />
        <SignInToContinueModal />
        <UpgradeToProPromoModal />
        <ContactSalesModal />
        <UpgradeToProModal />
        <BillingPlanSelectModal />
        <PaymentSuccessModal />
        <PaymentFailedModal />
        <RegenerateTaskModal />
        <TaskSharingModal />
        <IcpLeadDeletingModal />
        <ConnectLinkedInModal />
        <LinkedInConnectedModal />
        <HubSpotConnectionStatusModal />

        <ConfirmModal />

        <SystemPromptSettingsModal />

        <Outlet />
      </>
    ),
    children: [
      {
        path: 'auth',
        element: <AuthRedirectPage />,
      },
      {
        path: 'sign-in',
        element: <SignInPage />,
      },
      {
        path: 'sign-out',
        element: <SignOutPage />,
      },
      {
        path: 'test-ui',
        element: <TestUIPage />,
      },
      {
        path: 'sales-bot',
        element: <SalesBotPage />,
      },
      {
        path: 'hubspot-redirect',
        element: <HubSpotRedirectPage />,
      },
      {
        path: 'hubspot-howto',
        element: <HubSpotHowTo />,
      },
      {
        path: '/',
        element: <HomePage />,
      },
      {
        element: <PlaygroundLayout />,
        children: [
          {
            path: 'playground',
            element: <PlaygroundPage />,
          },
          {
            path: 'playground/t/:taskId',
            element: <PlaygroundTaskPage />,
          },
          {
            path: 'shared/t/:taskPublicId',
            element: (
              <RedirectInsideWorkspace>
                <PublicTaskPage />
              </RedirectInsideWorkspace>
            ),
          },
        ],
      },
      {
        path: ':workspaceId',
        element: (
          <AuthGuard strategy="redirect_signin">
            <>
              <HubSpotAutoconnect />
              <Outlet />
            </>
          </AuthGuard>
        ),
        children: [
          {
            element: <WorkspaceLayout />,
            children: [
              {
                index: true,
                element: <WorkspacePage />,
              },
              {
                path: 't/:taskId',
                element: <WorkspaceTaskPage />,
              },
              {
                path: 'shared/t/:taskPublicId',
                element: <PublicTaskPage />,
              },
              {
                path: 'icp/:icpId',
                element: <IcpWrapperPage />,
                children: [
                  {
                    index: true,
                    element: <IcpRootPage />,
                  },
                  {
                    path: 'campaign',
                    element: <IcpCampaignPage />,
                  },
                  {
                    path: 'view',
                    element: <IcpViewPage />,
                  },
                  {
                    path: 'outreach/:leadId',
                    element: <IcpOutreachDetailsPage />,
                  },
                  {
                    path: 'edit',
                    children: [
                      {
                        index: true,
                        element: <IcpEditRedirect />,
                      },
                      {
                        path: icpHelpers.getIcpStageRoute(IcpStage.SetContext),
                        element: <IcpContextPage />,
                      },
                      {
                        path: icpHelpers.getIcpStageRoute(IcpStage.ReferenceLeads),
                        element: <IcpLeadsPage />,
                      },
                      {
                        path: icpHelpers.getIcpStageRoute(IcpStage.MessageTemplates),
                        element: <IcpTemplatesPage />,
                      },
                      {
                        path: icpHelpers.getIcpStageRoute(IcpStage.Confirmation),
                        element: <IcpConfirmationPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
])

const Root: React.FC = () => {
  return (
    <QueryClientProvider client={QueryService.getClient()}>
      <TooltipProvider>
        <RouterProvider router={router} />
      </TooltipProvider>
    </QueryClientProvider>
  )
}

export default Root
