import { UserMe } from '@/modules/user/model'
import { ApiService } from '@/services/api'

import { userMeAdapter } from './userApiAdapters'
import { ConnectLinkedInResponse, UserMeResponse } from './userApiInterfaces'

export const getUserMe = async (): Promise<UserMe> => {
  const response = await ApiService.workspaceInstance.get<UserMeResponse>('/profile/me')

  return userMeAdapter(response.data)
}

export const sendCustomPlanRequest = async (name: string, email: string, comment: string) => {
  await ApiService.workspaceInstance.post('/profile/quote', { name, email, comment })
}

export const connectLinkedIn = async (redirect?: string): Promise<string> => {
  const response = await ApiService.workspaceInstance.post<ConnectLinkedInResponse>(
    '/profile/connect-linkedin',
    { redirect },
  )

  return response.data.url
}

export const disconnectLinkedIn = async () => {
  await ApiService.workspaceInstance.post('/profile/disconnect-linkedin')
}

export const connectHubSpot = async () => {
  const response = await ApiService.workspaceInstance.get<{ url: string }>(
    '/crm-apps/hubspot/connect',
  )

  return response.data.url
}

export const disconnectHubSpot = async () => {
  await ApiService.workspaceInstance.post('/crm-apps/hubspot/disconnect')
}
