import { Button, Modal } from '@onsaui'
import { IconHubSpot } from '@onsaui/icons'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import useAuth from '@/modules/auth/hooks/useAuth'

const HubSpotConnectionStatusModal: React.FC = () => {
  const [modalState, setModalState] = useState<'close' | 'success' | 'failed'>('close')

  const { isAuth } = useAuth()

  const [searchParams, setSearchParams] = useSearchParams()
  const isHubSpotConnectionSuccess = searchParams.get('hubspot_connection') === 'success'
  const isHubSpotConnectionFailed = searchParams.get('hubspot_connection') === 'failed'

  useEffect(() => {
    if (!isAuth) {
      return
    }

    if (isHubSpotConnectionSuccess || isHubSpotConnectionFailed) {
      if (isHubSpotConnectionSuccess) {
        setModalState('success')
      }
      if (isHubSpotConnectionFailed) {
        setModalState('failed')
      }

      setSearchParams((params) => {
        params.delete('hubspot_connection')
        return params
      })
    }
  }, [isAuth, isHubSpotConnectionSuccess, isHubSpotConnectionFailed, setSearchParams])

  const handleClose = () => {
    setModalState('close')
  }

  return (
    <Modal
      className="flex w-full max-w-sm flex-col items-center text-center"
      isOpen={modalState !== 'close'}
      onClose={handleClose}
    >
      <IconHubSpot className="mb-2 h-24 w-24 rounded-full text-primary" />
      {modalState === 'success' ? (
        <h2 className="font-semibold">HubSpot successfully connected!</h2>
      ) : (
        <h2 className="font-semibold">
          HubSpot connection <span className="text-danger">failed</span>!
        </h2>
      )}

      <Button variant="primary" onClick={handleClose} className="mt-8 w-full">
        Continue
      </Button>
    </Modal>
  )
}

export default HubSpotConnectionStatusModal
