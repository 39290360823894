import { notify } from '@onsaui'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'

import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { userActions } from '@/modules/user'
import useUserMe from '@/modules/user/hooks/useUserMe'

const HubSpotAutoconnect: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const isConnectHubSpotFlag = searchParams.get('connect_hubspot') === 'true'

  const { data: userMe } = useUserMe()

  const handleConnectHubSpot = async () => {
    const toastId = toast.loading('Connecting to HubSpot...', {
      className: '!bg-success !text-white',
    })

    try {
      await userActions.connectHubSpot()
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
    } finally {
      toast.dismiss(toastId)
    }
  }

  useEffect(() => {
    if (isConnectHubSpotFlag && userMe) {
      setSearchParams((params) => {
        params.delete('connect_hubspot')
        return params
      })

      if (!userMe.hubSpotConnection.isActive) {
        handleConnectHubSpot()
      } else {
        notify({ message: 'HubSpot already connected!', variant: 'success' })
      }
    }
  }, [isConnectHubSpotFlag, setSearchParams, userMe])

  return null
}

export default HubSpotAutoconnect
