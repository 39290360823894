import { Button, notify } from '@onsaui'
import cx from 'clsx'
import { useState } from 'react'

import { openConfirmModal } from '@/modules/shared'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { userActions } from '@/modules/user'
import useUserMe from '@/modules/user/hooks/useUserMe'
import AnalyticsService from '@/services/analytics'
import RenderQuery from '@/shared/component/RenderQuery/RenderQuery'

const HubSpotConnectionWidget: React.FC<{ className?: string }> = ({ className }) => {
  const userQuery = useUserMe()

  const [isConnecting, setIsConnecting] = useState(false)
  const handleConnect = async () => {
    AnalyticsService.trackEvent('HubSpotConnectionWidget.BtnConnect.Click')
    setIsConnecting(true)
    try {
      await userActions.connectHubSpot()
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsConnecting(false)
    }
  }

  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const handleDisconnect = async () => {
    AnalyticsService.trackEvent('HubSpotConnectionWidget.BtnDisconnect.Click')

    const isConfirmed = await openConfirmModal({
      body: (
        <div className="flex flex-col items-center text-center">
          <h2 className="mb-4 font-semibold">Disconnect HubSpot</h2>
          <p className="mb-4">Are you sure you want to disconnect HubSpot account?</p>
          <a
            className="text-primary"
            href={userQuery.data?.linkedInConnection?.linkedInUrl}
            target="_blank"
            rel="noreferrer"
          >
            {userQuery.data?.linkedInConnection?.linkedInUrl}
          </a>
        </div>
      ),
      footerAlign: 'col',
      confirmLabel: 'Disconnect',
      action: () => userActions.disconnectHubSpot(),
    })

    if (isConfirmed) {
      notify({ message: 'HubSpot account disconnected', variant: 'success' })
    }
  }

  return (
    <RenderQuery className={className} query={userQuery}>
      {({ hubSpotConnection }) => {
        return (
          <div className={cx('rounded-2xl bg-disabled-surface p-4', className)}>
            <div className="flex flex-row items-center">
              <p className="flex-grow text-sm text-secondary">
                {hubSpotConnection?.isActive
                  ? 'HubSpot account connected'
                  : 'Connect HubSpot integration'}
              </p>
              {hubSpotConnection?.isActive ? (
                <Button
                  size="small"
                  variant="ghost"
                  onClick={handleDisconnect}
                  isDisabled={isDisconnecting}
                >
                  Disconnect
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleConnect}
                  isDisabled={isConnecting}
                >
                  Connect
                </Button>
              )}
            </div>
          </div>
        )
      }}
    </RenderQuery>
  )
}

export default HubSpotConnectionWidget
