import { Navigate, useSearchParams } from 'react-router-dom'

import useAuth from '@/modules/auth/hooks/useAuth'

const HubSpotRedirectPage: React.FC = () => {
  const { isAuth } = useAuth()

  const [searchParams] = useSearchParams()
  const allSearchParams = searchParams.toString()

  if (!isAuth) {
    return <Navigate to={`/sign-in?redirect=/?${allSearchParams}`} replace />
  }

  return <Navigate to={`/?${allSearchParams}`} replace />
}

export default HubSpotRedirectPage
