/* eslint-disable react/no-unescaped-entities */
import { Button, OnsaLogo } from '@onsaui'

import scrConnect from './screens/connect.jpg'
import scrHsAddCard from './screens/hs_add_card.jpg'
import scrHsAddTab from './screens/hs_add_tab.jpg'
import scrHsApprove from './screens/hs_approve.jpg'
import scrHsMemo from './screens/hs_memo.jpg'
import scrHsMemoResult from './screens/hs_memo_result.jpg'
import scrHsSettings from './screens/hs_settings.jpg'
import scrSignIn from './screens/sign_in.jpg'

const HubSpotHowTo: React.FC = () => {
  return (
    <div className="flex min-h-full flex-col items-center bg-[#EFF2F6]">
      <div className="flex w-full flex-row items-center justify-center bg-level1">
        <div className="flex w-full max-w-3xl flex-row items-center py-4">
          <OnsaLogo />

          <div className="flex-grow" />

          <Button asChild size="medium">
            <a href="/" target="_self" rel="noreferrer">
              Home
            </a>
          </Button>
        </div>
      </div>
      <div className="flex w-full max-w-3xl flex-col gap-8 py-6">
        <div>
          <h1 className="mb-0.5 font-semibold">How to Connect Your HubSpot Account with Onsa</h1>
          <p>
            In this <strong>3-minute guide</strong>, you will learn how to connect your HubSpot
            account with Onsa.
          </p>
        </div>

        <div>
          <h2 className="mb-0.5">
            <span className="font-semibold">Step 1:</span> Sign In or Sign Up
          </h2>
          <p className="mb-2">
            First, sign in or sign up for your Onsa account:{' '}
            <a href="/sign-in" target="_blank" rel="noreferrer" className="text-primary">
              Sign in to Onsa
            </a>
          </p>
          <img src={scrSignIn} className="max-w-2xl" alt="Sign-In" />
        </div>

        <div>
          <h2 className="mb-0.5">
            <span className="font-semibold">Step 2:</span> Connect HubSpot
          </h2>
          <ul className="mb-2 list-inside list-disc pl-4">
            <li>
              Open your profile menu by clicking on your avatar in the top-right corner of the
              screen.
            </li>
            <li>Find the "Connect HubSpot integration" section.</li>
            <li>Click the "Connect" button.</li>
          </ul>

          <img src={scrConnect} className="max-w-2xl" alt="Connect HubSpot" />
        </div>

        <div>
          <h2 className="mb-0.5">
            <span className="font-semibold">Step 3:</span> Approve the Connection
          </h2>
          <p className="mb-2">You will be redirected to HubSpot, where you need to:</p>
          <ul className="mb-2 list-inside list-disc pl-4">
            <li>Select the HubSpot account you want to connect to Onsa.</li>
            <li>Review the requested permissions (scopes).</li>
            <li>Click "Connect app".</li>
            <li>You will be redirected back to the Onsa platform and see a success message.</li>
            <li>That&apos;s it! The integration is now connected.</li>
          </ul>
          <img src={scrHsApprove} className="max-w-2xl" alt="Approve Connection" />
        </div>

        <div>
          <h2 className="mb-0.5">
            <span className="font-semibold">Step 4:</span> Set Up the Onsa Tab in HubSpot
          </h2>
          <p className="mb-2">
            Now, you need to set up the Onsa tab in HubSpot to access memos easily.
          </p>
          <ul className="mb-2 list-inside list-disc pl-4">
            <li>In your HubSpot account, go to "Settings".</li>
            <li>Search for "Objects &gt; Contacts".</li>
            <li>Open the "Record Customization" section and click "Default View".</li>
          </ul>
          <img src={scrHsSettings} className="mb-6 max-w-2xl" alt="HubSpot settings" />

          <ul className="mb-2 list-inside list-disc pl-4">
            <li>In the opened window, click the "+" (plus) button to add a new tab.</li>
            <li>Name the tab "Onsa" and save it.</li>
          </ul>
          <img src={scrHsAddTab} className="mb-6 max-w-2xl" alt="HubSpot add tab" />

          <ul className="mb-2 list-inside list-disc pl-4">
            <li>Click "Add Cards".</li>
            <li>Find "Onsa: Person Memo Card" and check the box to activate it.</li>
            <li>Click "Save and Exit" to apply the changes.</li>
          </ul>
          <img src={scrHsAddCard} className="mb-6 max-w-2xl" alt="HubSpot add card" />

          <p>Great! You have successfully added a custom Onsa tab for your HubSpot contacts.</p>
        </div>

        <div>
          <h2 className="mb-0.5">
            <span className="font-semibold">Step 5:</span> Generate a Contact Memo and Get Insights
          </h2>
          <p className="mb-2">Now, you can start using Onsa with your HubSpot account!</p>
          <ul className="mb-2 list-inside list-disc pl-4">
            <li>Open any contact in HubSpot.</li>
            <li>Navigate to the "Onsa" tab.</li>
            <li>You will see an empty state with a button to generate a memo for the contact.</li>
            <li>
              If the contact does not have a linked in url, enter it in the "Linked In" input field.
            </li>
            <li>Click "Create Memo".</li>
          </ul>
          <img src={scrHsMemo} className="mb-6 max-w-2xl" alt="HubSpot memo card" />

          <p className="mb-2">
            After a short time, a Person Memo will be displayed, containing data and insights based
            on the contact’s LinkedIn profile.
          </p>
          <img src={scrHsMemoResult} className="max-w-2xl" alt="HubSpot memo result" />
        </div>
      </div>
    </div>
  )
}

export default HubSpotHowTo
